import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';
import Services from '../components/Services/Services';
import Hero from '../components/Hero/Hero';
import Aux from '../components/Aux/Aux';

const usluge = ({ data }) => {
  const heroImage = data.heroImage.edges[0].node.childImageSharp.fluid;
  //const bgImage = data.bgImage.edges[0].node.childImageSharp.fluid;

  return (
    <Layout>
      <SEO title="Usluge - Monolit" />

      <Hero image={heroImage} text="Usluge" breadcrumbs={true} />

      <Aux
        tag="section"
        // fluidImage={bgImage}
      >
        <div className="container padding-1">
          <Services type="all" />
        </div>
      </Aux>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroImage: allFile(filter: { relativeDirectory: { eq: "hero/services" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    bgImage: allFile(
      filter: { relativePath: { eq: "backgrounds/background-1.jpeg" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default usluge;
